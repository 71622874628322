import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, useLocation } from 'react-router-dom'
import './scss/style.scss'
import * as cusUtil from 'src/viewsCommon/CusUtil'

// 키오스크에 따라 동적로딩
if (window.location.href.includes('kioskMain')) {
  console.log('Kiosk main')
} else {
  console.log('Default main')
  // import('./firebase-messaging-sw.js')
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const cid = cusUtil.getCid() // App 에서는 cid 에 _ 붙여서 처리

// Pages
// const LoginOri = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
var Login
var AdminLogin
var Register
var Contract
var SpaceUse
var CenterDoorCode
var PasswordInit

if (cid === 'passit') {
  Login = require('./viewsCus/_company/login/Login_' + cid).default
  AdminLogin = React.lazy(() => import('./viewsCus/login/AdminLogin'))
  Register = require('./viewsCus/_company/login/Register_' + cid).default
  // Contract = React.lazy(() => import('./viewsCus/login/Contract'))
  // SpaceUse = React.lazy(() => import('./viewsCus/login/SpaceUse'))
  CenterDoorCode = React.lazy(() => import('./viewsCus/login/CenterDoorCode'))
  PasswordInit = require('./viewsCus/_company/login/PasswordInit_' + cid).default
} else {
  Login = React.lazy(() => import('./viewsCus/login/Login'))
  AdminLogin = React.lazy(() => import('./viewsCus/login/AdminLogin'))
  Register = React.lazy(() => import('./viewsCus/login/Register'))
  Contract = React.lazy(() => import('./viewsCus/login/Contract'))
  SpaceUse = React.lazy(() => import('./viewsCus/login/SpaceUse'))
  CenterDoorCode = React.lazy(() => import('./viewsCus/login/CenterDoorCode'))
  PasswordInit = React.lazy(() => import('./viewsCus/login/PasswordInit'))
}

// Email App
// const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))

const KioskMain = React.lazy(() => import('./viewsCus/_company_kiosk/KioskMain'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/alogin" name="Admin login Page" element={<AdminLogin />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/contract" name="Contract Page" element={<Contract />} />
            <Route exact path="/spaceUse" name="SpaceUse Page" element={<SpaceUse />} />
            <Route
              exact
              path="/centerDoorCode"
              name="CenterDoorCode Page"
              element={<CenterDoorCode />}
            />
            <Route exact path="/passwordInit" name="PasswordInit Page" element={<PasswordInit />} />
            {/* <Route exact path="/loginOri" name="Login Page" element={<LoginOri />} /> */}
            {/* <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="/apps/email/*" name="Email App" element={<EmailApp />} /> */}
            <Route path="/kioskMain" name="Kiosk" element={<KioskMain />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
